<template>
    <div class="center">
        <ul>
            <li @click="goUserData">
                <div>
                    <p>{{this.$t('个人信息管理')}}</p>
                    <p>{{this.$t('我们仅存储最少的个人信息。')}}</p>
                    <p>{{this.$t('成员通过其账号ID和电子邮件地址确定他们自己。')}}</p>
                </div>
                <div><img src="../../assets/img/icon_information.png" alt=""></div>
            </li>
            <li @click="goCoupn">
                <div>
                    <p>{{this.$t('优惠券管理')}}</p>
                    <p>{{this.$t('您可以检查优惠券详细信息。')}}</p>
                    <p>{{this.$t('检查详细信息后，如果有任何问题，请联系客服中心。')}}</p>
                </div>
                <div><img src="../../assets/img/icon_discount.png" alt=""></div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    components: {
    },
    beforeCreate(){
        var query=this.$route.query;
        let token = query.token;
        if (token) {
            this.$store.commit("setToken", token);
            this.$router.replace({
                path: this.$route.path})
        }
    },
    mounted(){
        let gettoken = window.localStorage.getItem("token")
        if(gettoken=='null'){
            this.$router.push('/login');
        }
    },
    methods:{
        goCoupn(){
            this.$router.push({
                path : "/mypage/coupon",
            })
        },
        goUserData(){
            this.$router.push({
                path : "/mypage/userdata",
            })
        }
    },
    
}
</script>

<style lang="scss">
.center{
    ul{
        li{
            background-color: #fff;
            margin: 20px 0;
            padding: 10px;
            display: flex;
            cursor: pointer;
            div{
                flex-grow: 1;
                &:nth-child(2){
                    text-align: right;
                }
            }
            p{
                color: #B3B3B3;
                font-size: 18px;
                &:nth-child(1){
                    color: #333333;
                    font-size: 22px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}
</style>